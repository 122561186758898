/* FONTS */

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style:normal;
}

/* APP */

:root {
  --almost-black-color: #333333;
  --dark-overlay-color: #33333343;
  --dark-grey-color: #4A4A4A;
  --warm-grey-color: #7B7A7A;
  --separator-grey-color: #C9C9C9;
  --light-background-grey-color: #F7F7F7;
  --rlaa-blue-color: #6D97CE;
  --error-red-color: #e60000;
  --success-green-color: #6ac127;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0px;
  font-weight: bold;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 80px;
  margin-bottom: 20px;
}

h2 {
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 22px;
  color: var(--warm-grey-color);
  font-weight: 800;
  margin: 0;
  margin-bottom: 5px;
}

input, textarea {
  display: block;
  margin: 0;
  width: 100%;
  color: var(--almost-black-color);
  border: 1px solid var(--warm-grey-color);
  height: 50px;
  padding: 0px 20px;
}

textarea {
  height: 250px;
  resize: none;
  padding: 20px;
}

button {
  border: none;
  background-color: var(--rlaa-blue-color);
  color:white;
  text-transform: uppercase;
  font-weight: 800;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  width: 200px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover {
  transform: scale(1.05);
}

button:disabled {
  transform: scale(1.00);
  opacity: 0.5;
  cursor: not-allowed;
}

.form-col {
  margin-bottom: 30px;
}

.dark h2 {
  color: var(--warm-grey-color);
}

h3 {
  font-size: 30px;
}

.active h3 {
  font-weight: bold;
}

p {
  font-size: 24px;
  line-height: 36px;
}

@media (max-width: 992px) {

  p {
    font-size: 20px;
    line-height: 30px;
  }

  h2 {
    margin: 20px 0px;
  }

  h3 {
    font-size: 23px;
  }

  .active h3 {
    font-weight: bold;
  }
}


.dark-overlay {
  background-color: var(--dark-overlay-color);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.light {
  color: white;
  transition: color 0.5s;
}

.logo text, .logo rect {
  transition: fill 0.5s;
}

.light .logo text, .light .logo rect {
  fill: white;
}

.dark {
  color: var(--dark-grey-color);
}

/* TAB LIST */

ul.tab-list {
  list-style: none;

  padding: 0;
  transition: all 0.5s;
}

ul.tab-list .tab-list-item {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

ul.tab-list .tab-list-item h3 {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.dark ul.tab-list .tab-list-item h3 {
  color: var(--warm-grey-color);
}

ul.tab-list .tab-list-item.active h3 {
  font-weight: bold;
}

.dark ul.tab-list .tab-list-item.active h3 {
  color: var(--almost-black-color);
}

ul.tab-list .tab-list-item .vertical-separator {
  background: white;
  width: 2px;
  height: 34px;
  margin: 0px 10px;
}

.dark ul.tab-list .tab-list-item .vertical-separator {
  background: var(--warm-grey-color);
}

ul.tab-list .tab-list-item .underline {
  width: 0px;
  height: 5px;
  background: white;
  transition: all 0.1s;
}

@media (max-width: 992px) {

  ul.tab-list .tab-list-item .vertical-separator {
    height: 24px;
  }
}

/* NAV */

nav {
  width: 100vw;
  height: 80px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
}

nav .nav-content {
  overflow: hidden !important;
}

nav .white-line {
  background: white;
  height: 5px;
  max-height: 80px;
  width: 100%;
  position: absolute;
  z-index: 2;
}

nav .container, nav .row {
  height: 80px;
}

nav .logo {
  height: 43px;
  width: auto;
  cursor: pointer !important;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

nav ul li {
  float: left;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

nav ul li .undeline {
  height: 1px;
  margin-top: 5px;
  background: white;
  width: 0px;
  margin-left: 50%;
  transition: all 0.3s;
}

.dark ul li .undeline {
  background: var(--dark-grey-color);
}

nav ul li:hover .undeline {
  width: 100%;
  margin-left: 0;
}

nav .hamburger {
  padding: 0;
  margin-bottom: -10px;
  display: none;
}

@media (max-width: 768px) {

  nav .hamburger {
    display: block;
  }

  nav .white-line {
    max-height: 100vh;
    transition: all 0.3s;
  }

  nav ul {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    top: -40px;
    left: -50vw;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  nav ul li {
    float:none;
    margin: 15px 0px;
  }

}

/* HEADER */

.header {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 50px;
  background-image: url("./assets/background.jpg");
  background-size: auto 150%;
  background-repeat: no-repeat;
  background-position: center 0px;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.header .container, .header .row {
  height: 100%;
}

.header p {
  font-weight: 600;
}

@media (max-width: 992px) {

  .header {
    padding-bottom: 10px;
  }
}

/* SERVICES */

.services {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s;
}

.services .dark-overlay {
  top: 100px;
  left: 100px;
  right: 100px;
  bottom: 100px;
  padding: 60px;
  display: flex;
  flex-direction: column;
}

.services-header {
  padding-bottom: 20px;
}

.services-content {
  flex: 1;
  text-align: justify;
}

.services .list-holder {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
}

.services .list-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 700px) {

  .services .dark-overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 60px 15px;
  }

  .services .list-holder {
    flex-direction: column;
  }

}

/* ABOUT */

.about {
  position: relative;
  padding: 100px 0px;
  background-color: var(--light-background-grey-color);
}

.about .about-img {
  width: 37vw;
  height: 37vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background 0.3s;
}

.about .about-img.d-lg-none {
  width: 100%;
  padding-bottom: 100%;
}

.about .about-img.absolute-top {
  position: absolute;
  top: 100px;
  left: 100px;
}

.about .about-img.absolute-bottom {
  position: absolute;
  bottom: 130px;
  left: 100px;
}

.about .about-img.fixed {
  position: fixed;
  top: 180px;
  left: 100px;
}

.about .fullname-text {
  font-size: 20px;
  color: var(--almost-black-color);
}


@media (max-width: 700px) {
  .about {
    padding: 60px 0;
  }
}

/* CONTACT */

.contact {
  padding: 100px 0px;
  background-color: white;
}

.contact .has-error input {
  border-color: var(--error-red-color);
}

.contact h2 {
  margin: 0;
  margin-bottom: 40px;
}

.contact button {
  display: block;
}

.contact p {
  text-align: right;
}

.contact .error {
  display: none;

  margin: 0 !important;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 18px;
  color: var(--error-red-color);
}

.contact .has-error .error {
  display: block;
}

@media (max-width: 700px) {
  .contact {
    padding: 60px 0;
  }
}

/* FOOTER */

footer .horizontal-separator {
  height: 1px;
  background-color: var(--separator-grey-color);
  width: 100%;
}

footer .content-row {
  margin: 50px 0px;
}

footer .middle-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

footer .separator {
  display: inline-block;
  width: 1px;
  background-color: var(--separator-grey-color);
  height: 120px;
  margin-left: -20px;
  margin-right: -20px;
}

footer .logo {
  width: 100px;
  height: 100px;
}

footer button {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0px 10px;
}

footer .contact-info {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
}

footer .contact-info strong {
  color: var(--rlaa-blue-color);
}

@media (max-width: 768px) {
  footer .middle-content {
    flex-direction: column;
  }

  footer .separator {
    height: 1px;
    width: 100%;
    margin: 30px 0px;
  }
}
